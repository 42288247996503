"use client";

import { PropsWithChildren } from "react";

import { toast } from "@repo/ui/hooks/useSonner";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const UNDEFINED_ERROR_MESSAGE = "data is undefined";

const mutationCache = new MutationCache({
  onError: (error) => {
    console.error(error);

    if (error.message === "success") {
      return;
    }

    if (!error.message.match(/[a-z]/i)) {
      return toast.error(error.message);
    }

    toast.error("에러가 발생했습니다. 다시 시도해주세요.");
  },
});

const queryCache = new QueryCache({
  onError: (error) => {
    toast.error("에러가 발생했습니다. 다시 시도해주세요.");

    if (error.message.indexOf(UNDEFINED_ERROR_MESSAGE) !== -1) {
      window.location.reload();
    }
  },
});
function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retryOnMount: true,
        refetchOnReconnect: false,
        retry: false,
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
      },
    },
    mutationCache,
    queryCache,
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

function RQProvider({ children }: PropsWithChildren) {
  const queryClient = getQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  );
}

export default RQProvider;

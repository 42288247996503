"use client";

import { signOutUseServer } from "@admin/service/actions/signInAction";
import CustomButton from "@repo/ui/components/common/CustomButton";

export const SignOutButton = () => {
  return (
    <CustomButton variant={"outline"} onClick={() => signOutUseServer()}>
      로그아웃
    </CustomButton>
  );
};
